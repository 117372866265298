import { Button, Card, Col, InputNumber, Row, Space, Table, message, Modal } from 'antd';
import { formatNumber, getCustomPrintParam, printHelper } from 'egenie-common';
import type { PaginationData, BaseData } from 'egenie-utils';
import { request, ImgFormatter } from 'egenie-utils';
import { action, computed, observable } from 'mobx';
import { Observer, observer } from 'mobx-react';
import qs from 'qs';
import React from 'react';
import type { ReceiptDetailItem } from './interface';
import styles from './subRoutes.less';
import { programme } from './index';

const max = 99999;
class Store {
  constructor() {
    const params = qs.parse(location.href.split('?')[1] || '') as { wmsReceiveOrderId: string; ownerName: string; };
    this.wmsReceiveOrderId = params.wmsReceiveOrderId;
    this.ownerName = params.ownerName;
    this.getData();
  }

  private wmsReceiveOrderId = '';

  public ownerName = '';

  @observable public isSearch = false;

  @action public getData = () => {
    this.isSearch = true;
    this.selectedRowKeys = [];
    request<PaginationData<ReceiptDetailItem>>({
      method: 'post',
      url: '/api/cloud/wms/rest/receiveOrder/pageQueryDetail',
      data: {
        wmsReceiveOrderId: this.wmsReceiveOrderId,
        page: 1,
        pageSize: 999999999,
      },
    })
      .then((info) => this.dataSource = (info?.data?.list || []).map((item, index) => ({
        ...item,
        _receiveRealNum: 0,
        _receiveDefectiveNum: 0,
        _index: index + 1,
      })))
      .finally(() => this.isSearch = false);
  };

  @action public handleCancel = () => {
    //
  };

  @action public handleSave = () => {
    const data = {
      wmsReceiveOrderId: this.wmsReceiveOrderId,
      detailVoList: this.dataSource.filter((item) => this.selectedRowKeys.includes(item.wmsReceiveOrderDetailId))
        .map((item) => ({
          wmsReceiveOrderDetailId: item.wmsReceiveOrderDetailId,
          actualRealReceiveNum: item._receiveRealNum,
          actualDefectiveReceiveNum: item._receiveDefectiveNum,
        })),
    };
    Modal.confirm({
      okText: '确定',
      cancelText: '取消',
      content: '确认提交?',
      onOk: () => request({
        method: 'POST',
        url: '/api/cloud/wms/rest/receiveOrder/confirmReceive',
        data,
      })
        .then(() => {
          message.success('保存成功');

          // 刷新
          programme?.gridModel?.onRefresh();
          this.handleCancel();
        }),
    });
  };

  @action public handlePrint = async() => {
    const detailList = this.dataSource.filter((item) => this.selectedRowKeys.includes(item.wmsReceiveOrderDetailId))
      .map((item) => ({
        wmsReceiveOrderDetailId: item.wmsReceiveOrderDetailId,
        num: item._receiveRealNum + item._receiveDefectiveNum,
      }));

    if (detailList.length === 0) {
      message.warning({
        key: '没有数据',
        content: '没有数据',
      });
      return;
    }

    if (!detailList.every((v) => v.num > 0)) {
      message.error({
        key: '请填写实际收货数量',
        content: '请填写实际收货数量',
      });
      return;
    }

    const { templateId, tempType, printer, preview } = await getCustomPrintParam('27');
    const printData = await request<BaseData<{ userDataList: any; tempData: any; }>>({
      url: '/api/cloud/print/wms/receive_order/get/unique/code/print/data',
      method: 'POST',
      data: {
        tempId: templateId,
        tempType,
        wmsReceiveOrderId: this.wmsReceiveOrderId,
        detailList,
      },
    });

    printHelper.toggleToLodop();
    await printHelper.print({
      preview,
      printer,
      templateData: printData?.data?.tempData,
      contents: printData?.data?.userDataList,
    });
  };

  @computed
  public get totalReceivePlanNum() {
    return this.dataSource.reduce((prev, current) => prev + formatNumber(current.receivePlanNum), 0);
  }

  @computed
  public get totalReceiveActualNum() {
    return this.dataSource.reduce((prev, current) => prev + formatNumber(current._receiveRealNum) + formatNumber(current._receiveDefectiveNum), 0);
  }

  @observable public selectedRowKeys: number[] = [];

  @action public onChange = (selectedRowKeys: number[]): void => {
    this.selectedRowKeys = selectedRowKeys;
  };

  @observable public dataSource: ReceiptDetailItem[] = [];

  public get columns() {
    return [
      {
        title: '序号',
        dataIndex: '_index',
        width: 50,
      },
      {
        title: '商品编码',
        dataIndex: 'productNo',
        width: 250,
      },
      {
        title: '图片',
        dataIndex: 'picUrl',
        width: 50,
        render: (url) => (
          <ImgFormatter value={url}/>
        ),
      },
      {
        title: '颜色',
        dataIndex: 'colorType',
        width: 80,
      },
      {
        title: '尺寸',
        dataIndex: 'sizeType',
        width: 80,
      },
      {
        title: '计划到货',
        dataIndex: 'receivePlanNum',
        width: 100,
      },
      {
        title: '实际收货正',
        dataIndex: '_receiveRealNum',
        width: 100,
        render: (_, row) => {
          return (
            <Observer>
              {
                () => (
                  <InputNumber
                    max={max}
                    min={0}
                    onChange={(value) => row._receiveRealNum = formatNumber(value)}
                    value={row._receiveRealNum}
                  />
                )
              }
            </Observer>
          );
        },
      },
      {
        title: '实际收货次',
        dataIndex: '_receiveDefectiveNum',
        width: 100,
        render: (_, row) => {
          return (
            <Observer>
              {
                () => (
                  <InputNumber
                    max={max}
                    min={0}
                    onChange={(value) => row._receiveDefectiveNum = formatNumber(value)}
                    value={row._receiveDefectiveNum}
                  />
                )
              }
            </Observer>
          );
        },
      },
      {
        title: '累计收货正',
        dataIndex: 'totalReceiveRealNum',
        width: 100,
      },
      {
        title: '累计收货次',
        dataIndex: 'totalReceiveDefectiveNum',
        width: 100,
        render: (text) => {
          return (
            <span style={{
              color: '#F2270A',
              fontWeight: 400,
            }}
            >
              {text}
            </span>
          );
        },
      },
    ];
  }
}

@observer
export default class extends React.Component {
  public store = new Store();

  render() {
    const {
      handleCancel,
      handleSave,
      handlePrint,
      columns,
      dataSource,
      selectedRowKeys,
      onChange,
      ownerName,
      totalReceivePlanNum,
      totalReceiveActualNum,
      isSearch,
    } = this.store;
    return (
      <div className={styles.container}>
        <Card style={{
          marginBottom: 20,
          flexShrink: 0,
        }}
        >
          <Row justify="space-between">
            <Col
              onClick={handleCancel}
              style={{ cursor: 'pointer' }}
            >
              <Space align="center">
                <span className={styles.goBackArrow}>
                  {'<'}
                </span>
                <span className={styles.goBackTitle}>
                  确认收货
                </span>
              </Space>
            </Col>
            <Col>
              <Space align="center">
                <Button onClick={handleCancel}>
                  取消
                </Button>
                <Button
                  disabled={selectedRowKeys.length < 1}
                  onClick={handleSave}
                  type="primary"
                >
                  提交并确认
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
        <Card style={{
          marginBottom: 20,
          flexShrink: 0,
        }}
        >
          <header className={styles.header}>
            <div className={styles.item}>
              <span/>
              基础信息
            </div>
          </header>
          <section className={styles.headContent}>
            <span>
              供应商:&nbsp;
              {ownerName}
            </span>
            <span>
              计划到货数量:&nbsp;
              {totalReceivePlanNum}
            </span>
            <span>
              实际收货数量:&nbsp;
              {totalReceiveActualNum}
            </span>
          </section>
        </Card>
        <Card style={{ flexGrow: 1 }}>
          <header className={styles.header}>
            <div className={styles.item}>
              <span/>
              商品明细
            </div>
            <div className={styles.item}>
              <Button
                className="ghost-bg-btn"
                ghost
                onClick={handlePrint}
              >
                打印条码
              </Button>
            </div>
          </header>
          <Table
            columns={columns}
            dataSource={dataSource.slice()}
            loading={isSearch}
            pagination={false}
            rowKey="wmsReceiveOrderDetailId"
            rowSelection={{
              selectedRowKeys,
              onChange,
            }}
            size="small"
          />
        </Card>
      </div>
    );
  }
}
